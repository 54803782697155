'use client';

import { createRequiredContext } from '~/utils/create-required-context';
import type { ReactNode } from 'react';
const [Provider, useContext] = createRequiredContext<string | undefined>();
export const NonceProvider = ({
  children,
  nonce
}: Readonly<{
  children?: ReactNode;
  nonce?: string;
}>) => {
  return <Provider value={nonce} data-sentry-element="Provider" data-sentry-component="NonceProvider" data-sentry-source-file="nonce-provider.tsx">
			{children}
		</Provider>;
};
export const useNonce = () => {
  return useContext();
};