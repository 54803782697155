/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const publicConfig = {
	app: {
		isDevelopment: process.env.NODE_ENV !== 'production',
		host: process.env.NEXT_PUBLIC_APP_HOST!,
		url: new URL(process.env.NEXT_PUBLIC_APP_HOST!),
		gitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA! || 'development',
		developer: process.env.NEXT_PUBLIC_YOUR_NAME!,
		chromeExtensionId: process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID!,
		homeUrl: 'https://trydunbar.com',
	},
	tailwind: {
		isIndicatorEnabled: process.env.NEXT_PUBLIC_ENABLE_TAILWIND_INDICATOR === 'true',
	},
	posthog: {
		key: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
	},
	ably: {
		publicKey: process.env.NEXT_PUBLIC_ABLY_KEY!,
		clientKey: process.env.NEXT_PUBLIC_ABLY_CLIENT_API_KEY!,
	},
	anon: {
		appConfig: {
			environment: process.env.NEXT_PUBLIC_ANON_ENV! as 'local'
				| 'development'
				| 'staging'
				| 'sandbox'
				| 'prod',
			clientId: process.env.NEXT_PUBLIC_ANON_SDKCLIENT_ID!,
			app: 'linkedin',
			company: 'Dunbar',
			companyLogo: new URL('/apple-touch-icon.png', `${process.env.NEXT_PUBLIC_APP_HOST}`).href,
			chromeExtensionId: process.env.NEXT_PUBLIC_ANON_CHROME_EXTENSION_ID!,
		},
		debug: process.env.DISABLE_ANON_DEBUG !== 'true',
	},
	knock: {
		publicKey: process.env.NEXT_PUBLIC_KNOCK_PUBLIC_KEY!,
		defaultChannel: process.env.NEXT_PUBLIC_KNOCK_DEFAULT_CHANNEL!,
	},
	csvbox: {
		sheetKey: process.env.NEXT_PUBLIC_CSVBOX_SHEET_KEY!,
		gmailUploadKey: process.env.NEXT_PUBLIC_CSVBOX_GMAIL_UPLOAD_KEY!,
	},
	paddle: {
		token: process.env.NEXT_PUBLIC_PADDLE_TOKEN!,
		customerPortalUrl: process.env.NEXT_PUBLIC_PADDLE_CUSTOMER_PORTAL_URL!,
	},
	gleap: {
		enabled: process.env.NEXT_PUBLIC_GLEAP_ENABLED === 'true',
		apiKey: process.env.NEXT_PUBLIC_GLEAP_API_KEY!,
	},

}

export const serverConfig = {
	app: {
		isDev: process.env.NODE_ENV === 'development',
		enableSlackNotifications: process.env.ENABLE_SLACK_NOTIFICATIONS === 'true',
		relevanceThreshold: '0.7',
		searchResultsLimit: 150,
		enableVectorsForLinkedInSync: false,
	},
	redis: {
		url: process.env.UPSTASH_REDIS_REST_URL!,
		token: process.env.UPSTASH_REDIS_REST_TOKEN!,
	},
	planetscale: {
		database: {
			username: process.env.PLANETSCALE_DB_USERNAME!,
			password: process.env.PLANETSCALE_DB_PASSWORD!,
			host: process.env.PLANETSCALE_DB_HOST!,
		},
		replicas: {
			username: process.env.PLANETSCALE_DB_REPLICA_USERNAME,
			password: process.env.PLANETSCALE_DB_REPLICA_PASSWORD,
			host: process.env.PLANETSCALE_DB_REPLICA_HOST,
		},
	},
	anon: {
		apiKey: process.env.ANON_API_KEY!,
		sdkClientId: process.env.NEXT_PUBLIC_ANON_SDKCLIENT_ID!,
		env: (process.env.NEXT_PUBLIC_ANON_ENV ?? 'sandbox') as 'sandbox' | 'prod',
	},
	clerk: {
		secretKey: process.env.CLERK_SECRET_KEY!,
		appId: process.env.CLERK_APP_ID!,
		instanceId: process.env.CLERK_INSTANCE_ID!,
	},
	contactout: {
		apiKey: process.env.CONTACTOUT_API_KEY!,
	},
	ably: {
		apiKey: process.env.ABLY_ADMIN_API_KEY!,
	},
	crustdata: {
		apiKey: process.env.CRUSTDATA_API_KEY!,
	},
	groq: {
		apiKey: process.env.GROQ_API_KEY!,
	},
	resend: {
		apiKey: process.env.RESEND_API_KEY!,
	},
	knock: {
		apiKey: process.env.KNOCK_API_KEY!,
		signingKey: process.env.KNOCK_SIGNING_KEY!,
	},
	mailercheck: {
		apiKey: process.env.MAILER_CHECK_API_KEY!,
	},
	paddle: {
		apiKey: process.env.PADDLE_API_KEY!,
	},
	proApis: {
		apiKey: process.env.PRO_APIS_API_KEY!,
	},
	slack: {
		botToken: process.env.SLACK_BOT_TOKEN!,
	},
	uploadthing: {
		token: process.env.UPLOADTHING_TOKEN!,
		apiKey: process.env.UPLOADTHING_API_KEY!,
	},
	christiansCustomerId: process.env.CHRISTIANS_CUSTOMER_ID!,
}

/* eslint-enable @typescript-eslint/no-non-null-assertion */
