'use client';

import { useUser } from '@clerk/nextjs';
import Gleap from 'gleap';
import { publicConfig } from '~/config';
import { useEffect } from 'react';
const GleapRenderer = () => {
  const {
    user
  } = useUser();
  useEffect(() => {
    if (publicConfig.gleap.enabled) {
      Gleap.initialize(publicConfig.gleap.apiKey);
      if (user) {
        const prefix = publicConfig.app.isDevelopment ? `dev_${publicConfig.app.developer}_` : 'prod_';
        const prefixedCustomerId = `${prefix}_${user.publicMetadata.customerId}`;
        Gleap.identify(prefixedCustomerId, {
          name: user.firstName,
          email: user.primaryEmailAddress?.emailAddress,
          customData: {
            clerkUserId: user.id
          }
        });
      } else {
        Gleap.clearIdentity();
      }
    }
  }, [user]);
  return null;
};
export default GleapRenderer;