'use client';

import nextDynamic from 'next/dynamic';
const PostHogPageView = nextDynamic(async () => {
  return import('./posthog-pageview');
}, {
  ssr: false
});
const PostHogPageViewRenderer = () => {
  return <PostHogPageView data-sentry-element="PostHogPageView" data-sentry-component="PostHogPageViewRenderer" data-sentry-source-file="posthog-pageview-renderer.tsx" />;
};
export default PostHogPageViewRenderer;