'use client';

import { type ReactNode, useEffect } from 'react';
import { getCookie } from 'cookies-next';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { publicConfig } from '~/config';
import { useNonce } from '~/app/_providers/nonce-provider';
const PHProvider = ({
  children
}: Readonly<{
  children: ReactNode;
}>) => {
  const nonce = useNonce();
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const flags = getCookie('bootstrapData') as string;
      const bootstrapData = (flags ? JSON.parse(flags.toString()) : {}) as Record<string, unknown>;
      posthog.init(publicConfig.posthog.key, {
        api_host: '/ingest',
        ui_host: 'https://us.posthog.com',
        capture_pageview: false,
        capture_pageleave: true,
        bootstrap: bootstrapData,
        person_profiles: 'identified_only',
        prepare_external_dependency_script: script => {
          if (nonce) {
            script.nonce = nonce;
          }
          return script;
        }
      });
    }
  }, [nonce]);
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-provider.tsx">
			{children}
		</PostHogProvider>;
};
export default PHProvider;